// src/pages/AuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../css/AuthCallback.module.css';

function AuthCallback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      // Store in localStorage so FeedViewer (and other pages) can pass it as Authorization header
      localStorage.setItem("jwt", token);
    }
    // Navigate to /feedviewer
    navigate("/feedviewer");
  }, [searchParams, navigate]);

  return (
    <div className={styles.authCallbackContainer}>
      <div className={styles.authCallbackBox}>
        <div className={styles.loadingText}>Loading...</div>
      </div>
    </div>
  );
}

export default AuthCallback;
