// src/pages/FeedAudit.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../css/FeedAudit.module.css';
import {
  FaDownload,
  FaCheck,
  FaArrowLeft,
  FaSearch,
  FaTimes,
  FaPlusCircle,
  FaSync
} from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import * as XLSX from 'xlsx'; // Import XLSX

// Define shared color constants for consistent usage across all audits
const AUDIT_COLORS = {
  // Length audit background colors
  EMPTY: '#ffcdd2',        // Light red
  INTERVAL_1: '#fff9c4',   // Light yellow
  INTERVAL_2: '#ffecb3',   // Light orange
  INTERVAL_3: '#c8e6c9',   // Light green
  INTERVAL_4: '#b2dfdb',   // Teal green,

  // Found in text colors
  COLOR: '#f44336',        // Red
  BRAND: '#ff9800',        // Orange
  SIZE: '#4caf50',         // Green
  GENDER: '#2196f3',       // Blue
  AGE_GROUP: '#9c27b0',    // Purple
  PATTERN: '#ff5722',      // Deep orange
  MATERIAL: '#009688',     // Teal
};

// Helper to attach Authorization header (Bearer token)
function getAuthHeader() {
  const token = localStorage.getItem("jwt");
  return token ? { Authorization: `Bearer ${token}` } : {};
}

function FeedAudit() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { feedId } = useParams();
  const navigate = useNavigate();

  // Title & description states
  const [titleAuditResults, setTitleAuditResults] = useState({});
  const [descriptionAuditResults, setDescriptionAuditResults] = useState({});
  const [loadingTitle, setLoadingTitle] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);

  // Error
  const [error, setError] = useState(null);

  // Language
  const [language, setLanguage] = useState('en');
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  // Inputs: color, brand, size, gender, age group, pattern, material
  const [colorInput, setColorInput] = useState('');
  const [brandInput, setBrandInput] = useState('');
  const [sizeInput, setSizeInput] = useState('');
  const [showSizeMenu, setShowSizeMenu] = useState(false);
  const [genderInput, setGenderInput] = useState('male, female, unisex');
  const [ageGroupInput, setAgeGroupInput] = useState('newborn, infant, toddler, kids, adult');
  const [patternInput, setPatternInput] = useState('');
  const [materialInput, setMaterialInput] = useState('');

  const [isCustomAuditStale, setIsCustomAuditStale] = useState(false);


  // Whether user has started an audit
  const [auditStarted, setAuditStarted] = useState(false);

  // Attribute audits
  const [attributeAuditResults, setAttributeAuditResults] = useState({});
  const [loadingAttributes, setLoadingAttributes] = useState(false);

  // Popups
  const [popupData, setPopupData] = useState(null);
  const [randomSamplePopupData, setRandomSamplePopupData] = useState(null);
  const [randomSamples, setRandomSamples] = useState([]);
  const [randomSamplePopupTitle, setRandomSamplePopupTitle] = useState('');
  const [showRandomSamplePopup, setShowRandomSamplePopup] = useState(false);
  const [loadingSamples, setLoadingSamples] = useState(false);
  const [currentSamplePopupType, setCurrentSamplePopupType] = useState('');

  // Attribute view type
  const [attributeView, setAttributeView] = useState('as_is');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc', view: 'as_is' });

  // Custom view
  const [customSelectedAttributes, setCustomSelectedAttributes] = useState([]);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState('');

  const validateIntervals = () => {
    let error = '';
    // Loop through each interval
    for (let i = 0; i < customLengthIntervals.length; i++) {
      const current = customLengthIntervals[i];
  
      // Check that if max is set, min is not greater than max
      if (current.max !== null && current.max !== undefined && current.min > current.max) {
        error = `Interval ${i + 1} is invalid: the minimum should be less than the maximum.`;
        break;
      }
      // For every interval except the first, ensure the current min equals the previous max plus one
      if (i > 0) {
        const previous = customLengthIntervals[i - 1];
        if (previous.max !== null && previous.max !== undefined) {
          if (current.min !== previous.max + 1) {
            error = `Interval ${i + 1} should start at ${previous.max + 1}.`;
            break;
          }
        }
      }
    }
    setIntervalError(error);
    // Return true if there is no error
    return error === '';
  };
  

  // The 4 custom intervals for the selected attribute's length audit
  //  (the first interval's min value is locked at 1)
  const [customLengthIntervals, setCustomLengthIntervals] = useState([
    { min: 1, max: 99 },
    { min: 100, max: 499 },
    { min: 500, max: 1499 },
    { min: 1500, max: null } // last interval, max is not editable (null = infinity)
  ]);
  const [intervalError, setIntervalError] = useState('');

  // Custom attribute result states
  const [customAttributeAuditResults, setCustomAttributeAuditResults] = useState(null);
  const [loadingCustomAttributeAudit, setLoadingCustomAttributeAudit] = useState(false);

  // Merchant center default ordering
  const merchantCenterOrder = [
    'item_group_id',
    'id',
    'gtin',
    'title',
    'description',
    'brand',
    'availability',
    'price',
    'size',
    'pattern',
    'material',
    'color',
    'gender',
    'age_group',
    'google_product_category',
    'product_type',
    'product_detail',
    'image_link',
    'additional_image_link',
    'lifestyle_image_link',
    'product_highlight',
    'product_page_url',
    'link'
  ];

  // Options for the multi-select in custom view
  const attributeOptions = Object.keys(attributeAuditResults).map(attribute => ({
    value: attribute,
    label: attribute
  }));

  const handleCustomViewChange = selectedOptions => {
    setCustomSelectedAttributes(selectedOptions || []);
  };

  // Local Storage helpers
  const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  // Count how many items are in a comma-separated list
  const countInputs = (inputString) => {
    return inputString
      .split(',')
      .map(item => item.trim())
      .filter(item => item !== '').length;
  };
  // Check if the number of items is above 2000
  const exceedsLimit = (input) => countInputs(input) > 2000;
  const isAnyInputExceedsLimit = () =>
    exceedsLimit(colorInput) ||
    exceedsLimit(brandInput) ||
    exceedsLimit(sizeInput) ||
    exceedsLimit(genderInput) ||
    exceedsLimit(ageGroupInput) ||
    exceedsLimit(patternInput) ||
    exceedsLimit(materialInput);

  // Predefined color sets
  const predefinedColors = {
    en: [
      'black', 'white', 'red', 'blue', 'green', 'yellow', 'orange', 'purple',
      'pink', 'brown', 'gray', 'grey', 'gold', 'silver', 'beige', 'ivory',
      'maroon', 'navy', 'violet', 'turquoise', 'peach', 'teal', 'coral',
      'lavender', 'mustard', 'burgundy', 'cyan', 'magenta', 'mint', 'olive',
      'salmon', 'tan', 'charcoal', 'indigo', 'plum', 'amber'
    ],
    dk: [
      'sort', 'hvid', 'rød', 'blå', 'grøn', 'gul', 'orange', 'lilla',
      'lyserød', 'brun', 'grå', 'sølv', 'guld', 'beige', 'elfenben', 'bordeaux',
      'marineblå', 'violet', 'turkis', 'fersken', 'teal', 'koraller', 'lavendel',
      'sennep', 'vinrød', 'cyan', 'magenta', 'mynte', 'oliven', 'laks', 'tan',
      'kul', 'indigo', 'blomme', 'rav'
    ],
    nl: [
      'zwart', 'wit', 'rood', 'blauw', 'groen', 'geel', 'oranje', 'paars',
      'roze', 'bruin', 'grijs', 'goud', 'zilver', 'beige', 'ivoor', 'kastanjebruin',
      'marineblauw', 'violet', 'turkoois', 'perzik', 'blauwgroen', 'koraal',
      'lavendel', 'mosterd', 'bordeaux', 'cyaan', 'magenta', 'munt', 'olijf',
      'zalm', 'tan', 'houtskool', 'indigo', 'pruim', 'amber'
    ],
    de: [
      'schwarz', 'weiß', 'rot', 'blau', 'grün', 'gelb', 'orange', 'lila',
      'rosa', 'braun', 'grau', 'gold', 'silber', 'beige', 'elfenbein', 'kastanienbraun',
      'marineblau', 'violett', 'türkis', 'pfirsich', 'blaugrün', 'koralle',
      'lavendel', 'senf', 'burgunderrot', 'cyan', 'magenta', 'minze', 'oliv',
      'lachs', 'sandbraun', 'anthrazit', 'indigo', 'pflaume', 'bernstein'
    ],
    no: [
      'svart', 'hvit', 'rød', 'blå', 'grønn', 'gul', 'oransje', 'lilla',
      'rosa', 'brun', 'grå', 'gull', 'sølv', 'beige', 'elfenben', 'kastanjebrun',
      'marineblå', 'fiolett', 'turkis', 'fersken', 'blågrønn', 'korall',
      'lavendel', 'sennep', 'vinrød', 'cyan', 'magenta', 'mynte', 'oliven',
      'laks', 'tan', 'kull', 'indigo', 'plomme', 'rav'
    ],
    se: [
      'svart', 'vit', 'röd', 'blå', 'grön', 'gul', 'orange', 'lila',
      'rosa', 'brun', 'grå', 'guld', 'silver', 'beige', 'elfenben', 'kastanjebrun',
      'marinblå', 'violett', 'turkos', 'persika', 'blågrön', 'korall',
      'lavendel', 'senap', 'vinröd', 'cyan', 'magenta', 'mynta', 'oliv',
      'lax', 'tan', 'kull', 'indigo', 'plommon', 'bärnsten'
    ],
    fi: [
      'musta', 'valkoinen', 'punainen', 'sininen', 'vihreä', 'keltainen',
      'oranssi', 'violetti', 'vaaleanpunainen', 'ruskea', 'harmaa', 'kulta',
      'hopea', 'beige', 'norsunluu', 'tummanpunainen', 'laivastonsininen',
      'violetti', 'turkoosi', 'persikka', 'sinivihreä', 'koralli', 'laventeli',
      'sinappi', 'viinipunainen', 'syaani', 'magenta', 'minttu', 'oliivi',
      'lohi', 'tan', 'hiili', 'indigo', 'luumu', 'meripihkanvärinen'
    ],
    es: [
      'negro', 'blanco', 'rojo', 'azul', 'verde', 'amarillo', 'naranja',
      'morado', 'rosa', 'marrón', 'gris', 'dorado', 'plateado', 'beige',
      'marfil', 'granate', 'azul marino', 'violeta', 'turquesa', 'melocotón',
      'verde azulado', 'coral', 'lavanda', 'mostaza', 'burdeos', 'cian',
      'magenta', 'menta', 'oliva', 'salmón', 'bronceado', 'carbón', 'índigo',
      'ciruela', 'ámbar'
    ]
  };

  // ------------------ API calls (with JWT header) ------------------
  const fetchTitleAuditResults = async (colors, brands, sizes, genders, ageGroups, patterns, materials) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/titles`, {
        feed_id: feedId,
        colors: colors,
        brands: brands,
        sizes: sizes,
        genders: genders,
        age_groups: ageGroups,
        patterns: patterns,
        materials: materials
      }, {
        headers: {
          ...getAuthHeader()
        }
      });
      setTitleAuditResults(response.data);
      saveToLocalStorage(`${feedId}_titleAuditResults`, response.data);
    } catch (error) {
      setError('Failed to fetch title audit results.');
    } finally {
      setLoadingTitle(false);
    }
  };

  const fetchDescriptionAuditResults = async (colors, brands, sizes, genders, ageGroups, patterns, materials) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/descriptions`, {
        feed_id: feedId,
        colors: colors,
        brands: brands,
        sizes: sizes,
        genders: genders,
        age_groups: ageGroups,
        patterns: patterns,
        materials: materials
      }, {
        headers: {
          ...getAuthHeader()
        }
      });
      setDescriptionAuditResults(response.data);
      saveToLocalStorage(`${feedId}_descriptionAuditResults`, response.data);
    } catch (error) {
      setError('Failed to fetch description audit results.');
    } finally {
      setLoadingDescription(false);
    }
  };

  const fetchAttributeAuditResults = async () => {
    setLoadingAttributes(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/attributes`, {
        feed_id: feedId
      }, {
        headers: {
          ...getAuthHeader()
        }
      });
      setAttributeAuditResults(response.data);
      saveToLocalStorage(`${feedId}_attributeAuditResults`, response.data);
    } catch (error) {
      setError('Failed to fetch attribute audit results.');
    } finally {
      setLoadingAttributes(false);
    }
  };

  const fetchBrandsAndSizes = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/get-brands-and-sizes?feed_id=${feedId}`,
        { headers: { ...getAuthHeader() } }
      );
      const brands = response.data.brands;
      const sizes = response.data.sizes;
      const patterns = response.data.patterns;
      const materials = response.data.materials;

      setBrandInput(brands.join(', '));
      setSizeInput(sizes.join(', '));
      setPatternInput(patterns.join(', '));
      setMaterialInput(materials.join(', '));
    } catch (error) {
      setError('Failed to fetch brands and sizes.');
    }
  };

  // Random samples for length intervals
  const openRandomSamplePopup = async (type, interval) => {
    try {
      setLoadingSamples(true);

      // Check if we're dealing with a "custom attribute length"
      const isCustomAttribute = type.includes('_length');
      let endpoint, requestData;

      if (isCustomAttribute) {
        // e.g. "product_id_length" -> "product_id"
        const attributeName = type.replace('_length', '');
        setCurrentSamplePopupType('attribute');

        endpoint = `${API_BASE_URL}/api/feed-audit/samples-by-attribute`;
        requestData = {
          feed_id: feedId,
          field: attributeName,
          attribute_type: 'length',
          interval: interval
        };
      } else {
        setCurrentSamplePopupType(type);
        endpoint = `${API_BASE_URL}/api/feed-audit/random-samples-${type}`;
        requestData = {
          feed_id: feedId,
          interval: interval
        };
      }

      const response = await axios.post(
        endpoint,
        requestData,
        {
          headers: {
            ...getAuthHeader()
          }
        }
      );

      setRandomSamples(response.data.samples);

      // Compose the popup title
      if (isCustomAttribute) {
        const attributeName = type.replace('_length', '');
        setRandomSamplePopupTitle(`${attributeName} with length ${interval}`);
      } else {
        setRandomSamplePopupTitle(`${type.charAt(0).toUpperCase() + type.slice(1)}s with length ${interval}`);
      }

      setShowRandomSamplePopup(true);
      setRandomSamplePopupData({type, interval});
    } catch (error) {
      console.error("Error fetching random samples:", error);
      setError(`Failed to fetch random samples: ${error.message}`);
    } finally {
      setLoadingSamples(false);
    }
  };

  // Colors for coverage percentage
  const getColorForPercentage = (value) => {
    if (value < 30) return '#ff4d4f';  // Red
    if (value < 60) return '#e8ca33';  // Yellow
    return '#52c41a';                 // Green
  };

  // Keydown (escape to close popups)
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setPopupData(null);
      setRandomSamplePopupData(null);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Whenever the user changes selectedLanguage, set colorInput accordingly
  useEffect(() => {
    const initialColors = predefinedColors[selectedLanguage].join(', ');
    setColorInput(initialColors);
  }, [selectedLanguage]);

  // Reset sort config if attributeView changes
  useEffect(() => {
    setSortConfig({ key: null, direction: 'asc', view: attributeView });
  }, [attributeView]);

  // Close the "top attribute values" popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupData && !document.getElementById('popupContent')?.contains(event.target)) {
        setPopupData(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [popupData]);

  // Close the random sample popup if clicked outside
  useEffect(() => {
    const handleClickOutsideRandomSamplePopup = (event) => {
      if (randomSamplePopupData && !document.getElementById('randomSamplePopupContent')?.contains(event.target)) {
        setRandomSamplePopupData(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideRandomSamplePopup);
    return () => document.removeEventListener('mousedown', handleClickOutsideRandomSamplePopup);
  }, [randomSamplePopupData]);

  // On first render, see if local storage has saved data (for brand, size, etc.)
  useEffect(() => {
    const savedData = loadFromLocalStorage(feedId);
    if (savedData) {
      setColorInput(savedData.colorInput || '');
      setBrandInput(savedData.brandInput || '');
      setSizeInput(savedData.sizeInput || '');
      setGenderInput(savedData.genderInput || '');
      setAgeGroupInput(savedData.ageGroupInput || '');
      setPatternInput(savedData.patternInput || '');
      setMaterialInput(savedData.materialInput || '');
      setAttributeView(savedData.attributeView || 'as_is');
      // Restore custom columns
      const restoredCustomAttributes = savedData.customSelectedAttributes?.map(attr => ({
        value: attr.value,
        label: attr.label
      })) || [];
      setCustomSelectedAttributes(restoredCustomAttributes);
    } else {
      // otherwise fetch brand & sizes from the server
      fetchBrandsAndSizes();
    }
  }, [feedId]); // eslint-disable-line react-hooks/exhaustive-deps

  // If we have local storage audit results, load them
  useEffect(() => {
    const savedTitleAuditResults = loadFromLocalStorage(`${feedId}_titleAuditResults`);
    const savedDescriptionAuditResults = loadFromLocalStorage(`${feedId}_descriptionAuditResults`);
    const savedAttributeAuditResults = loadFromLocalStorage(`${feedId}_attributeAuditResults`);

    if (savedTitleAuditResults && savedDescriptionAuditResults && savedAttributeAuditResults) {
      setTitleAuditResults(savedTitleAuditResults);
      setDescriptionAuditResults(savedDescriptionAuditResults);
      setAttributeAuditResults(savedAttributeAuditResults);
      setAuditStarted(true);
    } else {
      // if not found, we try to fetch from server once user hits "Apply"
      fetchBrandsAndSizes();
    }
  }, [feedId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Once we have feedId, also fetch the available attributes for the custom attribute section
  useEffect(() => {
    if (feedId) {
      fetchAvailableAttributes();
    }
  }, [feedId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Grab the available attributes from the feed
  const fetchAvailableAttributes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/feed-audit/available-attributes`, {
        params: { feed_id: feedId },
        headers: {
          ...getAuthHeader()
        }
      });
      setAvailableAttributes(response.data.attributes);

      // Set a default attribute if we have at least one
      if (response.data.attributes.length > 0) {
        setSelectedAttribute(response.data.attributes[0]);
      }
    } catch (error) {
      setError('Failed to fetch available attributes.');
    }
  };

  // Kick off custom attribute audit
  const fetchCustomAttributeAuditResults = async () => {
    try {
      setLoadingCustomAttributeAudit(true);
  
      const colorsArray = colorInput.split(',').map(color => color.trim());
      const brandsArray = brandInput.split(',').map(brand => brand.trim());
      const sizesArray = sizeInput.split(',').map(size => size.trim());
      const gendersArray = genderInput.split(',').map(gender => gender.trim());
      const ageGroupsArray = ageGroupInput.split(',').map(ageGroup => ageGroup.trim());
      const patternsArray = patternInput.split(',').map(pattern => pattern.trim());
      const materialsArray = materialInput.split(',').map(material => material.trim());
  
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/custom-attribute`, {
        feed_id: feedId,
        attribute: selectedAttribute,
        length_intervals: customLengthIntervals,
        colors: colorsArray,
        brands: brandsArray,
        sizes: sizesArray,
        genders: gendersArray,
        age_groups: ageGroupsArray,
        patterns: patternsArray,
        materials: materialsArray
      }, {
        headers: {
          ...getAuthHeader()
        }
      });
  
      setCustomAttributeAuditResults(response.data);
      saveToLocalStorage(`${feedId}_customAttributeAuditResults`, response.data);
      // Reset the stale flag once new data is available
      setIsCustomAuditStale(false);
    } catch (error) {
      console.error("Error in custom attribute audit:", error);
      setError('Failed to fetch custom attribute audit results.');
    } finally {
      setLoadingCustomAttributeAudit(false);
    }
  };
  

  // Lock the first interval's min at 1, and handle changes for the rest
  const updateLengthInterval = (index, field, value) => {
    // For the first interval, the min is locked at 1
    if (index === 0 && field === 'min') return;
  
    // Create a copy of the intervals array
    const newIntervals = [...customLengthIntervals];
  
    if (field === 'min') {
      // Update the current interval's min
      newIntervals[index] = {
        ...newIntervals[index],
        min: value === '' ? null : parseInt(value, 10)
      };
  
      // Adjust the previous interval's max so that it ends at current min - 1
      if (index > 0) {
        newIntervals[index - 1] = {
          ...newIntervals[index - 1],
          max:
            newIntervals[index].min !== null
              ? newIntervals[index].min - 1
              : newIntervals[index - 1].max
        };
      }
    } else if (field === 'max') {
      // Update the current interval's max
      newIntervals[index] = {
        ...newIntervals[index],
        max: value === '' ? null : parseInt(value, 10)
      };
  
      // Auto-adjust the next interval's min to be current max + 1
      if (index < customLengthIntervals.length - 1) {
        newIntervals[index + 1] = {
          ...newIntervals[index + 1],
          min:
            newIntervals[index].max !== null
              ? newIntervals[index].max + 1
              : newIntervals[index + 1].min
        };
      }
    }
  
    setCustomLengthIntervals(newIntervals);
    // Validate the new intervals to check for any gaps or overlaps.
    validateIntervals();
    // Mark the audit as stale so the table shows "..." until refreshed.
    setIsCustomAuditStale(true);
  };
  

  // Called when user hits "Apply" after entering brand, color, etc.
  const handleApply = () => {
    if (isAnyInputExceedsLimit()) {
      alert('One or more inputs exceed the limit of 2000 items. Please reduce the number before proceeding.');
      return;
    }
    // Save current state to localStorage
    saveToLocalStorage(feedId, {
      colorInput,
      brandInput,
      sizeInput,
      genderInput,
      ageGroupInput,
      patternInput,
      materialInput,
      attributeView,
      customSelectedAttributes,
    });
  
    setAuditStarted(true);
    setLanguage(selectedLanguage);
    setLoadingTitle(true);
    setLoadingDescription(true);
    setLoadingAttributes(true);
  
    const colorsArray = colorInput.split(',').map(color => color.trim());
    const brandsArray = brandInput.split(',').map(brand => brand.trim());
    const sizesArray = sizeInput.split(',').map(size => size.trim());
    const gendersArray = genderInput.split(',').map(gender => gender.trim());
    const ageGroupsArray = ageGroupInput.split(',').map(ageGroup => ageGroup.trim());
    const patternsArray = patternInput.split(',').map(pattern => pattern.trim());
    const materialsArray = materialInput.split(',').map(material => material.trim());
  
    // Title + Description + All attributes
    fetchTitleAuditResults(colorsArray, brandsArray, sizesArray, gendersArray, ageGroupsArray, patternsArray, materialsArray);
    fetchDescriptionAuditResults(colorsArray, brandsArray, sizesArray, gendersArray, ageGroupsArray, patternsArray, materialsArray);
    fetchAttributeAuditResults();
  
    // Remove the following, so custom audit runs separately:
    // if (selectedAttribute) {
    //   fetchCustomAttributeAuditResults();
    // }
  };
  

  // Sorting
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, view: attributeView });
  };
  const sortData = (data) => {
    if (!sortConfig.key) return data;
    const sortedData = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedData;
  };
  const getDefaultSortedData = (data) => {
    if (attributeView === 'merchant_center') {
      // Sort MC attributes based on merchantCenterOrder index
      return data.sort((a, b) => {
        return merchantCenterOrder.indexOf(a.attribute) - merchantCenterOrder.indexOf(b.attribute);
      });
    }
    return data;
  };

  // Popup for top attribute values
  const openPopup = (attribute) => {
    setPopupData({
      attribute: attribute,
      topValues: attributeAuditResults[attribute].top_values
    });
  };

  // Format percentage
  const formatPercentage = (value) =>
    value !== undefined && value !== null ? value.toFixed(1) + '%' : 'N/A';

  // Handle changes in input fields
  const handleInputChange = (setter, value) => {
    setter(value);
  };

  // Download to XLSX
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    // Helper function to replace undefined/null with 0
    const sanitizeValue = (value) => (value === undefined || value === null ? 0 : value);

    // 1) Title Audit sheet
    const titleAuditData = [
      ['Avg. Length', 'Empty', '1-24', '25-49', '50-74', '>75', 'Color', 'Gender', 'Age Group', 'Brand', 'Size', 'Pattern', 'Material'],
      [
        sanitizeValue(titleAuditResults?.average_title_length),
        sanitizeValue(titleAuditResults?.title_null_or_zero),
        sanitizeValue(titleAuditResults?.title_length_1_24),
        sanitizeValue(titleAuditResults?.title_length_25_49),
        sanitizeValue(titleAuditResults?.title_length_50_74),
        sanitizeValue(titleAuditResults?.title_length_75_plus),
        sanitizeValue(titleAuditResults?.appearing_title_color),
        sanitizeValue(titleAuditResults?.appearing_title_gender),
        sanitizeValue(titleAuditResults?.appearing_title_age_group),
        sanitizeValue(titleAuditResults?.appearing_title_brand),
        sanitizeValue(titleAuditResults?.appearing_title_size),
        sanitizeValue(titleAuditResults?.appearing_title_pattern),
        sanitizeValue(titleAuditResults?.appearing_title_material),
      ],
      [
        '', // placeholder
        sanitizeValue(titleAuditResults?.title_null_or_zero_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_1_24_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_25_49_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_50_74_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_75_plus_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_color_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_gender_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_age_group_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_brand_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_size_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_pattern_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_material_percentage) + '%',
      ],
    ];
    const wsTitle = XLSX.utils.aoa_to_sheet(titleAuditData);
    XLSX.utils.book_append_sheet(wb, wsTitle, 'Title Audit');

    // 2) Description Audit sheet
    const descriptionAuditData = [
      ['Avg. Length', 'Empty', '1-99', '100-499', '500-1499', '>1500', 'Color', 'Gender', 'Age Group', 'Brand', 'Size', 'Pattern', 'Material'],
      [
        sanitizeValue(descriptionAuditResults?.average_description_length),
        sanitizeValue(descriptionAuditResults?.description_null_or_zero),
        sanitizeValue(descriptionAuditResults?.description_length_1_100),
        sanitizeValue(descriptionAuditResults?.description_length_101_500),
        sanitizeValue(descriptionAuditResults?.description_length_501_1500),
        sanitizeValue(descriptionAuditResults?.description_length_1500_plus),
        sanitizeValue(descriptionAuditResults?.appearing_description_color),
        sanitizeValue(descriptionAuditResults?.appearing_description_gender),
        sanitizeValue(descriptionAuditResults?.appearing_description_age_group),
        sanitizeValue(descriptionAuditResults?.appearing_description_brand),
        sanitizeValue(descriptionAuditResults?.appearing_description_size),
        sanitizeValue(descriptionAuditResults?.appearing_description_pattern),
        sanitizeValue(descriptionAuditResults?.appearing_description_material),
      ],
      [
        '', // placeholder
        sanitizeValue(descriptionAuditResults?.description_null_or_zero_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_1_100_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_101_500_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_501_1500_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_1500_plus_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_color_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_gender_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_age_group_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_brand_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_size_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_pattern_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_material_percentage) + '%',
      ],
    ];
    const wsDescription = XLSX.utils.aoa_to_sheet(descriptionAuditData);
    XLSX.utils.book_append_sheet(wb, wsDescription, 'Desc. Audit');

    // 3) Attribute Audit (as is)
    const attributeAuditAsIsData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...Object.keys(attributeAuditResults).map(attr => [
        attr,
        sanitizeValue(attributeAuditResults[attr]?.percentage) + '%',
        sanitizeValue(attributeAuditResults[attr]?.not_empty),
        sanitizeValue(attributeAuditResults[attr]?.top_values?.[0]?.[0]),
      ]),
    ];
    const wsAttributesAsIs = XLSX.utils.aoa_to_sheet(attributeAuditAsIsData);
    XLSX.utils.book_append_sheet(wb, wsAttributesAsIs, 'Attrib. (as is)');

    // 4) Attribute Audit (merchant center)
    const merchantCenterData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...merchantCenterOrder.map(attr => {
        const attrData = attributeAuditResults[attr];
        if (!attrData) {
          return [attr, '0%', 0, 'N/A'];
        }
        return [
          attr,
          sanitizeValue(attrData.percentage) + '%',
          sanitizeValue(attrData.not_empty),
          sanitizeValue(attrData.top_values?.[0]?.[0]),
        ];
      }),
    ];
    const wsAttributesMerchantCenter = XLSX.utils.aoa_to_sheet(merchantCenterData);
    XLSX.utils.book_append_sheet(wb, wsAttributesMerchantCenter, 'Attrib. (MC)');

    // 5) Attribute Audit (custom)
    const customAttributesData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...customSelectedAttributes.map(attr => [
        attr.label,
        sanitizeValue(attributeAuditResults[attr.value]?.percentage) + '%',
        sanitizeValue(attributeAuditResults[attr.value]?.not_empty),
        sanitizeValue(attributeAuditResults[attr.value]?.top_values?.[0]?.[0]),
      ]),
    ];
    const wsAttributesCustom = XLSX.utils.aoa_to_sheet(customAttributesData);
    XLSX.utils.book_append_sheet(wb, wsAttributesCustom, 'Attrib. (custom)');

    // 6) Items Found
    const itemsFoundData = [
      ['Total Items Found', sanitizeValue(titleAuditResults?.total_titles)],
    ];
    const wsItemsFound = XLSX.utils.aoa_to_sheet(itemsFoundData);
    XLSX.utils.book_append_sheet(wb, wsItemsFound, 'Items Found');

    XLSX.writeFile(wb, 'FeedAuditResults.xlsx');
  };

  // Add size systems
  const handleAddSizeSystem = (system) => {
    const sizeSystems = {
      'eu_shoes': [
        "35", "35.5", "36", "36.5", "37", "37.5", "38", "38.5", "39", "39.5",
        "40", "40.5", "41", "41.5", "42", "42.5", "43", "43.5", "44", "44.5",
        "45", "45.5", "46"
      ],
      'eu_clothes': [
        "32", "34", "36", "38", "40", "42", "44", "46", "48", "50",
        "XS", "S", "M", "L", "XL", "XXL", "XXXL"
      ],
      'eu_kids': [
        "92", "98", "104", "110", "116", "122", "128", "134", "140"
      ],
      'eu_baby': [
        "50", "56", "62", "68", "74", "80"
      ],
      'eu_rings': [
        "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64"
      ],
      'eu_hats': [
        "54", "55", "56", "57", "58", "59", "60", "61", "62"
      ],
      'eu_gloves': [
        "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11"
      ],
      'eu_watches': [
        "38 mm", "40 mm", "42 mm", "44 mm", "46 mm", "48 mm"
      ],
      'eu_belts': [
        "80", "85", "90", "95", "100", "105", "110", "115", "120"
      ],
      'eu_swimwear': [
        "32", "34", "36", "38", "40", "42", "44", "46",
        "XS", "S", "M", "L", "XL", "XXL"
      ]
    };

    const newSizes = sizeSystems[system] || [];
    const current = sizeInput
      ? sizeInput.split(',').map(s => s.trim()).filter(Boolean)
      : [];

    const merged = [...current, ...newSizes];
    const uniqueMerged = [...new Set(merged)];

    setSizeInput(uniqueMerged.join(', '));
    setShowSizeMenu(false);
  };

  // For searching attribute samples
  const openAttributeSamplePopup = async (attribute, category) => {
    try {
      setLoadingSamples(true);

      // Distinguish title/description vs. a generic attribute
      if (attribute === 'title') {
        setCurrentSamplePopupType('title');
      } else if (attribute === 'description') {
        setCurrentSamplePopupType('description');
      } else {
        setCurrentSamplePopupType('attribute');
      }

      // Build arrays from comma‐separated state
      const colorsArray = colorInput.split(',').map(s => s.trim()).filter(Boolean);
      const brandsArray = brandInput.split(',').map(s => s.trim()).filter(Boolean);
      const sizesArray = sizeInput.split(',').map(s => s.trim()).filter(Boolean);
      const gendersArray = genderInput.split(',').map(s => s.trim()).filter(Boolean);
      const ageGroupsArray = ageGroupInput.split(',').map(s => s.trim()).filter(Boolean);
      const patternsArray = patternInput.split(',').map(s => s.trim()).filter(Boolean);
      const materialsArray = materialInput.split(',').map(s => s.trim()).filter(Boolean);

      const response = await axios.post(
        `${API_BASE_URL}/api/feed-audit/samples-by-attribute`,
        {
          feed_id: feedId,
          field: attribute,
          attribute_type: category,
          limit: 20,
          colors: colorsArray,
          brands: brandsArray,
          sizes: sizesArray,
          genders: gendersArray,
          age_groups: ageGroupsArray,
          patterns: patternsArray,
          materials: materialsArray,
        },
        { headers: { ...getAuthHeader() } }
      );

      setRandomSamples(response.data.samples);
      setRandomSamplePopupTitle(`${attribute} with ${category}`);
      setShowRandomSamplePopup(true);
      setRandomSamplePopupData({ attribute, category });
    } catch (error) {
      console.error("Error fetching attribute samples:", error);
      setError("Failed to fetch attribute samples.");
    } finally {
      setLoadingSamples(false);
    }
  };

  // Re-run custom attribute if user changes attribute etc.
  useEffect(() => {
    if (
      auditStarted &&
      titleAuditResults &&
      descriptionAuditResults &&
      selectedAttribute &&
      !customAttributeAuditResults
    ) {
      // Auto-run custom audit – remove this call to decouple it
      // fetchCustomAttributeAuditResults();
    }
  }, [auditStarted, titleAuditResults, descriptionAuditResults, selectedAttribute]);
  

  return (
    <div className={styles.pageContainer}>
      {/* Top bar */}
      <div className={styles.topBar}>
        <button
          onClick={() => navigate(`/feedviewer/${feedId}`)}
          className={styles.backButton}
        >
          <FaArrowLeft size={20} />
        </button>
        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Feed Viewer 2.0</span>
          <span className={styles.headerSubtitle}>Feed Audit</span>
        </div>
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>

      <div className={styles.content}>
        {/* Configuration Container */}
        <div className={styles.configurationContainer}>
          <p><b>Based on Predefined Values:</b></p>

          <div className={styles.languageAndColorContainer}>
            <div className={styles.languageSelector}>
              <label htmlFor="colorInput">
                Colors{' '}
                <span className={exceedsLimit(colorInput) ? styles.warningMessage : ''}>
                  (#{countInputs(colorInput)})
                </span>
                :
              </label>
              <select
                id="language"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="dk">Danish</option>
                <option value="nl">Dutch</option>
                <option value="de">German</option>
                <option value="no">Norwegian</option>
                <option value="se">Swedish</option>
                <option value="fi">Finnish</option>
                <option value="es">Spanish</option>
              </select>
            </div>

            <div className={styles.colorInputContainer}>
              <input
                type="text"
                id="colorInput"
                value={colorInput}
                onChange={(e) => handleInputChange(setColorInput, e.target.value)}
                className={styles.colorInput}
              />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="genderInput">
              Genders{' '}
              <span className={exceedsLimit(genderInput) ? styles.warningMessage : ''}>
                (#{countInputs(genderInput)})
              </span>
              :
            </label>
            <input
              type="text"
              id="genderInput"
              value={genderInput}
              onChange={(e) => handleInputChange(setGenderInput, e.target.value)}
              className={styles.inputField}
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="ageGroupInput">
              Age Groups{' '}
              <span className={exceedsLimit(ageGroupInput) ? styles.warningMessage : ''}>
                (#{countInputs(ageGroupInput)})
              </span>
              :
            </label>
            <input
              type="text"
              id="ageGroupInput"
              value={ageGroupInput}
              onChange={(e) => handleInputChange(setAgeGroupInput, e.target.value)}
              className={styles.inputField}
            />
          </div>

          <hr className={styles.divider} />

          <p><b>Based on Feed Values:</b></p>

          <div className={styles.inputContainer}>
            <label htmlFor="brandInput">
              Brands{' '}
              <span className={exceedsLimit(brandInput) ? styles.warningMessage : ''}>
                (#{countInputs(brandInput)})
              </span>
              :
            </label>
            <input
              type="text"
              id="brandInput"
              value={brandInput}
              onChange={(e) => handleInputChange(setBrandInput, e.target.value)}
              className={styles.inputField}
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="sizeInput">
              Sizes{' '}
              <span className={exceedsLimit(sizeInput) ? styles.warningMessage : ''}>
                (#{countInputs(sizeInput)})
              </span>
              :
            </label>

            <div className={styles.sizeInputWrapper}>
              <input
                id="sizeInput"
                type="text"
                value={sizeInput}
                onChange={(e) => handleInputChange(setSizeInput, e.target.value)}
                className={styles.inputField}
              />

              {/* Button to toggle the size menu */}
              <button
                type="button"
                onClick={() => setShowSizeMenu(!showSizeMenu)}
                className={styles.addSizeSystemButton}
              >
                <FaPlusCircle size={18} />
              </button>

              {showSizeMenu && (
                <div className={styles.sizeMenu}>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_shoes')}>
                    Shoes
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_clothes')}>
                    Clothes
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_kids')}>
                    Kids' Clothes
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_baby')}>
                    Baby Clothes
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_rings')}>
                    Rings
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_hats')}>
                    Hats
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_gloves')}>
                    Gloves
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_watches')}>
                    Watches
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_belts')}>
                    Belts
                  </div>
                  <div className={styles.menuOption} onClick={() => handleAddSizeSystem('eu_swimwear')}>
                    Swimwear
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="patternInput">
              Patterns{' '}
              <span className={exceedsLimit(patternInput) ? styles.warningMessage : ''}>
                (#{countInputs(patternInput)})
              </span>
              :
            </label>
            <input
              type="text"
              id="patternInput"
              value={patternInput}
              onChange={(e) => handleInputChange(setPatternInput, e.target.value)}
              className={styles.inputField}
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="materialInput">
              Materials{' '}
              <span className={exceedsLimit(materialInput) ? styles.warningMessage : ''}>
                (#{countInputs(materialInput)})
              </span>
              :
            </label>
            <input
              type="text"
              id="materialInput"
              value={materialInput}
              onChange={(e) => handleInputChange(setMaterialInput, e.target.value)}
              className={styles.inputField}
            />
          </div>

          {!isAnyInputExceedsLimit() && (
            <div className={styles.buttonContainer}>
              <button
                onClick={handleApply}
                className={styles.applyFilterButton}
                disabled={loadingTitle || loadingDescription || loadingAttributes}
              >
                {(loadingTitle || loadingDescription || loadingAttributes) ? (
                  <ClipLoader size={20} color="#ffffff" />
                ) : (
                  <FaCheck />
                )}
                {(loadingTitle || loadingDescription || loadingAttributes) ? null : " Apply"}
              </button>

              <button
                onClick={handleDownload}
                className={styles.downloadButton}
                disabled={!auditStarted}
              >
                <FaDownload />
              </button>
            </div>
          )}

          {isAnyInputExceedsLimit() && (
            <p className={styles.warningMessage}>
              ⚠️ Max 2000 labels: This audit is blocked due to too many labels provided.
            </p>
          )}
        </div>

        {/* Items Found Card */}
        {auditStarted && titleAuditResults && (
          <div className={styles.countOfProductsContainer}>
            <div className={styles.countOfProducts}>
              <h3 className={styles.auditTitle} style={{ margin: 0 }}>Product Overview</h3>
              <div>
                Total Items Found:
                <span className={styles.itemsFoundValue}>
                  {loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.total_titles}
                </span>
              </div>
            </div>
          </div>
        )}

        {error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <>
            {/* Title Audit */}
            {auditStarted && titleAuditResults && (
              <div className={styles.auditResult}>
                <h3 className={styles.auditTitle}>Title</h3>
                <table className={styles.auditTable}>
                  <thead>
                    <tr>
                      <th colSpan="6">Length Audit</th>
                      <th colSpan="7">Found in Title</th>
                    </tr>
                    <tr>
                      <th>Avg. Length</th>
                      <th>Empty</th>
                      <th>
                        1-24
                        <button
                          onClick={() => openRandomSamplePopup('title', 'length_1_24')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        25-49
                        <button
                          onClick={() => openRandomSamplePopup('title', 'length_25_49')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        50-74
                        <button
                          onClick={() => openRandomSamplePopup('title', 'length_50_74')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        =&gt;75
                        <button
                          onClick={() => openRandomSamplePopup('title', 'length_75_plus')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Color
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'colors')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Gender
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'genders')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Age Group
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'age_groups')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Brand
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'brands')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Size
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'sizes')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Pattern
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'patterns')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Material
                        <button
                          onClick={() => openAttributeSamplePopup('title', 'materials')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : `${titleAuditResults?.average_title_length} characters`}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_null_or_zero}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_1_24}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_25_49}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_50_74}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_75_plus}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_color}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_gender}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_age_group}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_brand}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_size}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_pattern}</td>
                      <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_material}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_null_or_zero_percentage)}</span></td>
                      <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_1_24_percentage)}</span></td>
                      <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_25_49_percentage)}</span></td>
                      <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_50_74_percentage)}</span></td>
                      <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_75_plus_percentage)}</span></td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_color_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_color_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_gender_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_gender_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_age_group_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_age_group_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_brand_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_brand_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_size_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_size_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_pattern_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_pattern_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_material_percentage) }}>
                          {loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_material_percentage)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {/* Description Audit */}
            {auditStarted && descriptionAuditResults && (
              <div className={styles.auditResult}>
                <h3 className={styles.auditTitle}>Description</h3>
                <table className={styles.auditTable}>
                  <thead>
                    <tr>
                      <th colSpan="6">Length Audit</th>
                      <th colSpan="7">Found in Description</th>
                    </tr>
                    <tr>
                      <th>Avg. Length</th>
                      <th>Empty</th>
                      <th>
                        1-99
                        <button
                          onClick={() => openRandomSamplePopup('description', 'length_1_100')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        100-499
                        <button
                          onClick={() => openRandomSamplePopup('description', 'length_101_500')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        500-1499
                        <button
                          onClick={() => openRandomSamplePopup('description', 'length_501_1500')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>
                        =&gt;1500
                        <button
                          onClick={() => openRandomSamplePopup('description', 'length_1500_plus')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Color
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'colors')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Gender
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'genders')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Age Group
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'age_groups')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Brand
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'brands')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Size
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'sizes')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Pattern
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'patterns')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                      <th>Material
                        <button
                          onClick={() => openAttributeSamplePopup('description', 'materials')}
                          className={styles.smallTableButton}
                        >
                          <FaSearch />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : `${descriptionAuditResults?.average_description_length} characters`}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_null_or_zero}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_1_100}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_101_500}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_501_1500}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_1500_plus}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_color}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_gender}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_age_group}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_brand}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_size}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_pattern}</td>
                      <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_material}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <span>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_null_or_zero_percentage)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_1_100_percentage)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_101_500_percentage)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_501_1500_percentage)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_1500_plus_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_color_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_color_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_gender_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_gender_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_age_group_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_age_group_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_brand_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_brand_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_size_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_size_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_pattern_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_pattern_percentage)}
                        </span>
                      </td>
                      <td className="appearing-attribute">
                        <span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_material_percentage) }}>
                          {loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_material_percentage)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {/* ------------------ CUSTOM ATTRIBUTE AUDIT SECTION START ------------------ */}
            {auditStarted && titleAuditResults && descriptionAuditResults && (
              <div className={styles.auditResult}>
                {/* HEADER + DROPDOWN + ICON BUTTON (Refresh Button Next to Dropdown) */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '20px'
                  }}
                >
                  <h3 className={styles.auditTitle} style={{ margin: 0 }}>
                    Custom Attribute Audit
                  </h3>
                  <Select
                    id="attributeSelect"
                    placeholder="Select attribute..."
                    value={selectedAttribute ? { value: selectedAttribute, label: selectedAttribute } : null}
                    onChange={(option) => {
                      setSelectedAttribute(option.value);
                      // Mark the custom audit as stale so it will run when the user triggers it
                      setIsCustomAuditStale(true);
                    }}
                    options={availableAttributes.map((attr) => ({
                      value: attr,
                      label: attr
                    }))}
                    className={styles.customAttributeSelect}  
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#f8f8f8',
                        borderColor: '#ccc',
                        color: '#292B3D',
                        '&:hover': { borderColor: '#4758EB' },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#ffffff',
                        color: '#292B3D',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? '#848EE0'
                          : state.isFocused
                          ? '#e0e1eb'
                          : '#ffffff',
                        color: state.isSelected ? '#ffffff' : '#292B3D',
                        '&:hover': {
                          backgroundColor: '#4758EB',
                          color: '#ffffff',
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#292B3D',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#888',
                      }),
                    }}
                  />
                  <button
                    onClick={fetchCustomAttributeAuditResults}
                    className={styles.runCustomAuditIconButton}
                    disabled={loadingCustomAttributeAudit || intervalError !== ''}
                    title="Run Custom Audit"
                  >
                    {loadingCustomAttributeAudit ? (
                      <ClipLoader size={18} color="#fff" />
                    ) : (
                      <FaSync size={18} />
                    )}
                  </button>
                </div>

                {/* INTERVAL CONTROLS */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                    marginBottom: '10px',
                    flexWrap: 'wrap'
                  }}
                >
                  <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>Length Intervals:</span>
                  
                  {/* Interval 1 (Locked min at 1) */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f8f8f8'
                    }}
                  >
                    <input
                      type="number"
                      min="1"
                      value={customLengthIntervals[0].min ?? ''}
                      readOnly
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                    <span style={{ margin: '0 5px' }}>-</span>
                    <input
                      type="number"
                      value={customLengthIntervals[0].max ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(0, 'max', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                  </div>
                  
                  {/* Interval 2 */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f8f8f8'
                    }}
                  >
                    <input
                      type="number"
                      value={customLengthIntervals[1].min ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(1, 'min', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                    <span style={{ margin: '0 5px' }}>-</span>
                    <input
                      type="number"
                      value={customLengthIntervals[1].max ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(1, 'max', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                  </div>
                  
                  {/* Interval 3 */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f8f8f8'
                    }}
                  >
                    <input
                      type="number"
                      value={customLengthIntervals[2].min ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(2, 'min', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                    <span style={{ margin: '0 5px' }}>-</span>
                    <input
                      type="number"
                      value={customLengthIntervals[2].max ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(2, 'max', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                  </div>
                  
                  {/* Interval 4 (Last interval; max not editable) */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f8f8f8'
                    }}
                  >
                    <input
                      type="number"
                      value={customLengthIntervals[3].min ?? ''}
                      onChange={(e) => {
                        updateLengthInterval(3, 'min', e.target.value);
                        setIsCustomAuditStale(true);
                      }}
                      style={{
                        width: '50px',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'center'
                      }}
                    />
                    <span style={{ margin: '0 5px' }}>+</span>
                  </div>
                </div>

                {/* Display Interval Error Message, if any */}
                {intervalError && (
                  <p style={{ color: 'red', marginBottom: '20px' }}>{intervalError}</p>
                )}

                {/* TABLE OF RESULTS (shows "..." if audit is stale) */}
                {loadingCustomAttributeAudit ? (
                  <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <ClipLoader size={30} />
                    <p>Loading {selectedAttribute} audit...</p>
                  </div>
                ) : (
                  customAttributeAuditResults && (
                    <>
                      <h3 className={styles.auditTitle}>
                        {customAttributeAuditResults.attribute}
                      </h3>
                      <table className={`${styles.auditTable} ${styles.lengthAuditTable}`}>
                        <thead>
                          <tr>
                            <th colSpan={2 + customLengthIntervals.length}>Length Audit</th>
                            <th colSpan="7">
                              Found in {customAttributeAuditResults.attribute}
                            </th>
                          </tr>
                          <tr>
                            <th className={styles.columnHeader}>Avg. Length</th>
                            <th className={styles.columnHeader}>Empty</th>
                            {customLengthIntervals.map((interval, idx) => {
                              const label = interval.max
                                ? `${interval.min}-${interval.max}`
                                : `${interval.min}+`;
                              return (
                                <th key={idx} className={styles.columnHeader}>
                                  {label}
                                  <button
                                    onClick={() =>
                                      openRandomSamplePopup(
                                        `${customAttributeAuditResults.attribute}_length`,
                                        label
                                      )
                                    }
                                    className={styles.smallTableButton}
                                  >
                                    <FaSearch />
                                  </button>
                                </th>
                              );
                            })}
                            <th className={styles.columnHeader}>
                              Color
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'colors'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Gender
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'genders'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Age Group
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'age_groups'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Brand
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'brands'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Size
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'sizes'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Pattern
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'patterns'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                            <th className={styles.columnHeader}>
                              Material
                              <button
                                onClick={() =>
                                  openAttributeSamplePopup(
                                    customAttributeAuditResults.attribute,
                                    'materials'
                                  )
                                }
                                className={styles.smallTableButton}
                              >
                                <FaSearch />
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Row for counts */}
                          <tr>
                            <td className={styles.avgLength}>
                              {isCustomAuditStale ? '...' : customAttributeAuditResults.length_audit.avg_length}
                              <br />
                              characters
                            </td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.length_audit.empty.count}</td>
                            {customLengthIntervals.map((interval) => {
                              const label = interval.max
                                ? `${interval.min}-${interval.max}`
                                : `${interval.min}+`;
                              const matchedInterval =
                                customAttributeAuditResults.length_audit.intervals.find(
                                  (i) => i.name === label
                                );
                              return (
                                <td key={label}>
                                  {isCustomAuditStale ? '...' : (matchedInterval ? matchedInterval.count : 0)}
                                </td>
                              );
                            })}
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.colors.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.genders.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.age_groups.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.brands.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.sizes.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.patterns.count}</td>
                            <td>{isCustomAuditStale ? '...' : customAttributeAuditResults.found_in.materials.count}</td>
                          </tr>
                          {/* Row for percentages */}
                          <tr>
                            <td />
                            <td>
                              <span className={styles.percentage}>
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.length_audit.empty.percentage)}
                              </span>
                            </td>
                            {customLengthIntervals.map((interval) => {
                              const label = interval.max
                                ? `${interval.min}-${interval.max}`
                                : `${interval.min}+`;
                              const matchedInterval =
                                customAttributeAuditResults.length_audit.intervals.find(
                                  (i) => i.name === label
                                );
                              return (
                                <td key={label}>
                                  <span className={styles.percentage}>
                                    {isCustomAuditStale
                                      ? '...'
                                      : (matchedInterval ? formatPercentage(matchedInterval.percentage) : '0%')}
                                  </span>
                                </td>
                              );
                            })}
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.colors.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.colors.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.genders.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.genders.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.age_groups.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.age_groups.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.brands.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.brands.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.sizes.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.sizes.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.patterns.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.patterns.percentage)}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getColorForPercentage(customAttributeAuditResults.found_in.materials.percentage)
                                }}
                                className={styles.percentage}
                              >
                                {isCustomAuditStale ? '...' : formatPercentage(customAttributeAuditResults.found_in.materials.percentage)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )
                )}
              </div>
            )}
            {/* ------------------ CUSTOM ATTRIBUTE AUDIT SECTION END ------------------ */}





            {/* Attribute Audit (all feed attributes) */}
            {auditStarted && attributeAuditResults && (
              <div className={styles.auditResult}>
                <div className={styles.auditHeader}>
                  <h3 className={styles.auditTitle}>Attribute Audit</h3>
                  <Select
                    value={{
                      value: attributeView,
                      label: attributeView
                        .replace('_', ' ')
                        .replace(/\b\w/g, (l) => l.toUpperCase()),
                    }}
                    onChange={(selectedOption) => setAttributeView(selectedOption.value)}
                    options={[
                      { value: 'as_is', label: 'As is' },
                      { value: 'merchant_center', label: 'Merchant center' },
                      { value: 'custom', label: 'Custom View' },
                    ]}
                    className={styles.attributeDropdown}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#f8f8f8',
                        borderColor: '#ccc',
                        color: '#292B3D',
                        '&:hover': { borderColor: '#4758EB' },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#ffffff',
                        color: '#292B3D',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? '#848EE0'
                          : state.isFocused
                          ? '#e0e1eb'
                          : '#ffffff',
                        color: state.isSelected ? '#ffffff' : '#292B3D',
                        '&:hover': {
                          backgroundColor: '#4758EB',
                          color: '#ffffff',
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#292B3D',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#888',
                      }),
                    }}
                  />

                  {attributeView === 'custom' && (
                    <Select
                      isMulti
                      options={attributeOptions}
                      onChange={handleCustomViewChange}
                      className={styles.customSelect}
                      placeholder="Select attributes..."
                      closeMenuOnSelect={false}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: '#f8f8f8',
                          borderColor: '#ccc',
                          color: '#292B3D',
                          minWidth: '300px',
                          maxWidth: '800px',
                          '&:hover': { borderColor: '#4758EB' },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: '#ffffff',
                          color: '#292B3D',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? '#848EE0'
                            : state.isFocused
                            ? '#e0e1eb'
                            : '#ffffff',
                          color: state.isSelected ? '#ffffff' : '#292B3D',
                          '&:hover': {
                            backgroundColor: '#4758EB',
                            color: '#ffffff',
                          },
                        }),
                        multiValue: (provided) => ({
                          ...provided,
                          backgroundColor: '#e0e1eb',
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: '#292B3D',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#292B3D',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#888',
                        }),
                      }}
                    />
                  )}
                </div>

                <table className={styles.attributeAuditTable}>
                  <thead>
                    <tr>
                      <th
                        className={styles.attributeColumn}
                        onClick={() => handleSort('attribute')}
                      >
                        {loadingAttributes ? <ClipLoader size={20} /> : 'Attribute'}
                      </th>
                      <th
                        className={styles.smallColumn}
                        onClick={() => handleSort('percentage')}
                      >
                        {loadingAttributes ? <ClipLoader size={20} /> : 'Coverage %'}
                      </th>
                      <th
                        className={styles.smallColumn}
                        onClick={() => handleSort('not_empty')}
                      >
                        {loadingAttributes ? <ClipLoader size={20} /> : 'Count #'}
                      </th>
                      <th
                        className={styles.frequentColumn}
                        onClick={() => handleSort('topValue')}
                      >
                        {loadingAttributes ? <ClipLoader size={20} /> : 'Most Frequent (Sample)'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* VIEW: AS IS */}
                    {attributeView === 'as_is' &&
                      sortData(
                        getDefaultSortedData(
                          Object.keys(attributeAuditResults).map((attribute) => {
                            const topValues = attributeAuditResults[attribute]?.top_values || [];
                            const uniqueValuesCount = topValues.length;
                            return {
                              attribute,
                              percentage: attributeAuditResults[attribute]?.percentage,
                              not_empty: attributeAuditResults[attribute]?.not_empty,
                              topValue: topValues.length > 0 ? topValues[0][0] : '',
                              topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                              uniqueValuesCount,
                            };
                          })
                        )
                      ).map((attr) => (
                        <tr key={attr.attribute}>
                          <td className={styles.attributeColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}
                          </td>
                          <td
                            className={styles.smallColumn}
                            style={{ color: getColorForPercentage(attr.percentage) }}
                          >
                            {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                          </td>
                          <td className={styles.smallColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                          </td>
                          <td className={styles.frequentColumn}>
                            {loadingAttributes ? (
                              <ClipLoader size={20} />
                            ) : (
                              <>
                                {attr.topValue.length > 50
                                  ? `${attr.topValue.substring(0, 50)}...`
                                  : attr.topValue}{' '}
                                ({attr.topValueCount})
                                {attr.uniqueValuesCount > 1 && (
                                  <button
                                    onClick={() => openPopup(attr.attribute)}
                                    className={styles.moreButton}
                                  >
                                    <FaSearch />
                                  </button>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}

                    {/* VIEW: MERCHANT CENTER */}
                    {attributeView === 'merchant_center' &&
                      sortData(
                        getDefaultSortedData(
                          merchantCenterOrder.map((merchantAttr) => {
                            const attributeData = attributeAuditResults[merchantAttr];
                            if (!attributeData) {
                              return {
                                attribute: merchantAttr,
                                percentage: 0,
                                not_empty: 0,
                                topValue: 'N/A',
                                topValueCount: 0,
                                uniqueValuesCount: 0,
                              };
                            }
                            const topValues = attributeData.top_values || [];
                            const uniqueValuesCount = topValues.length;
                            return {
                              attribute: merchantAttr,
                              percentage: attributeData.percentage,
                              not_empty: attributeData.not_empty,
                              topValue: topValues.length > 0 ? topValues[0][0] : '',
                              topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                              uniqueValuesCount,
                            };
                          })
                        )
                      ).map((attr) => (
                        <tr key={attr.attribute}>
                          <td className={styles.attributeColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}
                          </td>
                          <td
                            className={styles.smallColumn}
                            style={{ color: getColorForPercentage(attr.percentage) }}
                          >
                            {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                          </td>
                          <td className={styles.smallColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                          </td>
                          <td className={styles.frequentColumn}>
                            {loadingAttributes ? (
                              <ClipLoader size={20} />
                            ) : (
                              <>
                                {attr.topValue.length > 50
                                  ? `${attr.topValue.substring(0, 50)}...`
                                  : attr.topValue}{' '}
                                ({attr.topValueCount})
                                {attr.uniqueValuesCount > 1 && (
                                  <button
                                    onClick={() => openPopup(attr.attribute)}
                                    className={styles.moreButton}
                                  >
                                    <FaSearch />
                                  </button>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}

                    {/* VIEW: CUSTOM */}
                    {attributeView === 'custom' &&
                      sortData(
                        customSelectedAttributes.map(attr => ({
                          attribute: attr.value,
                          label: attr.label,
                          percentage: attributeAuditResults[attr.value]?.percentage || 0,
                          not_empty: attributeAuditResults[attr.value]?.not_empty || 0,
                          topValue:
                            attributeAuditResults[attr.value]?.top_values?.[0]?.[0] || '',
                          topValueCount:
                            attributeAuditResults[attr.value]?.top_values?.[0]?.[1] || 0,
                          uniqueValuesCount:
                            attributeAuditResults[attr.value]?.top_values?.length || 0,
                        }))
                      ).map(attr => (
                        <tr key={attr.attribute}>
                          <td className={styles.attributeColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.label}
                          </td>
                          <td
                            className={styles.smallColumn}
                            style={{ color: getColorForPercentage(attr.percentage) }}
                          >
                            {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                          </td>
                          <td className={styles.smallColumn}>
                            {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                          </td>
                          <td className={styles.frequentColumn}>
                            {loadingAttributes ? (
                              <ClipLoader size={20} />
                            ) : (
                              <>
                                {attr.topValue.length > 50
                                  ? `${attr.topValue.substring(0, 50)}...`
                                  : attr.topValue}{' '}
                                ({attr.topValueCount})
                                {attr.uniqueValuesCount > 1 && (
                                  <button
                                    onClick={() => openPopup(attr.attribute)}
                                    className={styles.moreButton}
                                  >
                                    <FaSearch />
                                  </button>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {/* Additional Attributes if user selected 'merchant_center' */}
                {attributeView === 'merchant_center' && (
                  <div className={styles.additionalAttributes}>
                    <table className={styles.attributeAuditTable}>
                      <thead>
                        <tr>
                          <th
                            className={styles.attributeColumn}
                            onClick={() => handleSort('attribute')}
                          >
                            Additional Attributes from feed{' '}
                            {sortConfig.key === 'attribute' &&
                              (sortConfig.direction === 'asc' ? '▲' : '▼')}
                          </th>
                          <th
                            className={styles.smallColumn}
                            onClick={() => handleSort('percentage')}
                          >
                            Coverage %{' '}
                            {sortConfig.key === 'percentage' &&
                              (sortConfig.direction === 'asc' ? '▲' : '▼')}
                          </th>
                          <th
                            className={styles.smallColumn}
                            onClick={() => handleSort('not_empty')}
                          >
                            Count #{' '}
                            {sortConfig.key === 'not_empty' &&
                              (sortConfig.direction === 'asc' ? '▲' : '▼')}
                          </th>
                          <th
                            className={styles.frequentColumn}
                            onClick={() => handleSort('topValue')}
                          >
                            Most Frequent (Sample){' '}
                            {sortConfig.key === 'topValue' &&
                              (sortConfig.direction === 'asc' ? '▲' : '▼')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortData(
                          getDefaultSortedData(
                            Object.keys(attributeAuditResults)
                              .filter(attr => !merchantCenterOrder.includes(attr))
                              .map((attribute) => {
                                const topValues = attributeAuditResults[attribute]?.top_values || [];
                                const uniqueValuesCount = topValues.length;
                                return {
                                  attribute,
                                  percentage: attributeAuditResults[attribute]?.percentage,
                                  not_empty: attributeAuditResults[attribute]?.not_empty,
                                  topValue: topValues.length > 0 ? topValues[0][0] : '',
                                  topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                                  uniqueValuesCount,
                                };
                              })
                          )
                        ).map((attr) => (
                          <tr key={attr.attribute}>
                            <td className={styles.attributeColumn}>
                              {loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}
                            </td>
                            <td
                              className={styles.smallColumn}
                              style={{ color: getColorForPercentage(attr.percentage) }}
                            >
                              {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                            </td>
                            <td className={styles.smallColumn}>
                              {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                            </td>
                            <td className={styles.frequentColumn}>
                              {loadingAttributes ? (
                                <ClipLoader size={20} />
                              ) : (
                                <>
                                  {attr.topValue.length > 50
                                    ? `${attr.topValue.substring(0, 50)}...`
                                    : attr.topValue}{' '}
                                  ({attr.topValueCount})
                                  {attr.uniqueValuesCount > 1 && (
                                    <button
                                      onClick={() => openPopup(attr.attribute)}
                                      className={styles.moreButton}
                                    >
                                      <FaSearch />
                                    </button>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}

            {/* Popup for top attribute values */}
            {popupData && (
              <div className={styles.popupOverlay} id="popupOverlay">
                <div className={styles.popupContent} id="popupContent">
                  <button onClick={() => setPopupData(null)} className={styles.closeIconButton}>
                    <FaTimes />
                  </button>
                  <h3>Top {popupData.topValues.length} Values for "{popupData.attribute}"</h3>

                  {(popupData.attribute === "description" ||
                    popupData.topValues.some((value) => value[0].includes("http"))) && (
                    <p style={{ textAlign: 'center' }}>
                      Please be aware only the top 10 links, images, videos, or very long texts are shown here.
                    </p>
                  )}

                  <table className={styles.popupTable}>
                    <thead>
                      <tr>
                        <th>{popupData.attribute}</th>
                        <th>Count #</th>
                      </tr>
                    </thead>
                    <tbody>
                      {popupData.topValues.map((value, index) => (
                        <tr key={index}>
                          <td>{value[0]}</td>
                          <td>{value[1]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* Popup for random samples */}
            {randomSamplePopupData && (
              <div className={styles.popupOverlay} id="randomSamplePopupOverlay">
                <div className={styles.popupContent} id="randomSamplePopupContent">
                  <button
                    onClick={() => {
                      setRandomSamplePopupData(null);
                      setShowRandomSamplePopup(false);
                    }}
                    className={styles.closeIconButton}
                  >
                    <FaTimes />
                  </button>
                  <h3>{randomSamplePopupTitle}</h3>
                  {loadingSamples ? (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                      <ClipLoader size={30} />
                    </div>
                  ) : (
                    <table className={styles.popupTable}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>
                            {currentSamplePopupType === 'title'
                              ? 'Title'
                              : currentSamplePopupType === 'description'
                              ? 'Description'
                              : 'Attribute Value'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {randomSamples.map((value, index) => (
                          <tr key={index}>
                            <td>{value.id}</td>
                            <td>
                              {currentSamplePopupType === 'title' && value.title}
                              {currentSamplePopupType === 'description' && value.description}
                              {currentSamplePopupType === 'attribute' && value.field_value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <p
        style={{
          textAlign: 'center',
          fontSize: '12px',
          color: '#666',
          margin: '20px 0',
        }}
      >
        Disclaimer: s360 Digital A/S is not responsible for the content displayed in this tool.
      </p>
    </div>
  );
}

export default FeedAudit;
