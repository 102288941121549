// src/pages/FeedViewer.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaArrowRight, FaLink, FaEye, FaCheck, FaPlay, FaPlus, FaDownload, FaPowerOff, FaTable } from 'react-icons/fa';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ClipLoader } from 'react-spinners'; 
import styles from '../css/FeedViewer.module.css';

const ItemType = 'COLUMN';

function getAuthHeader() {
  const token = localStorage.getItem("jwt");
  return token ? { Authorization: `Bearer ${token}` } : {};
}

function DraggableColumn({ column, index, moveColumn, onRemove }) {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} className={styles.selectedColumnItem}>
      {column} <span className={styles.deleteIcon} onClick={() => onRemove(column)}>&times;</span>
    </li>
  );
}

function FeedViewer() {
  const { feedId: urlFeedId } = useParams();
  const [feedId, setFeedId] = useState(urlFeedId || null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const feedUrlParam = new URLSearchParams(window.location.search).get('url');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [itemTag, setItemTag] = useState('item');
  const [feedUrl, setFeedUrl] = useState('');
  const [feedFile, setFeedFile] = useState(null);
  const [data, setData] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [inputPage, setInputPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedAvailableColumns, setSelectedAvailableColumns] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [columnsLoaded, setColumnsLoaded] = useState(false);

  const [filters, setFilters] = useState([{ column: '', type: 'contains', value: '', logic: 'AND' }]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [orderDir, setOrderDir] = useState('ASC');

  const [isSuccess, setIsSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [loadingSheets, setLoadingSheets] = useState(false);
  const [sheetsUrl, setSheetsUrl] = useState(null);
  const [showSheetsSuccess, setShowSheetsSuccess] = useState(false);

  const navigate = useNavigate();
  const autoLoadRef = useRef(false);

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    navigate('/');
  };

  const handleLoadXml = () => {
    setData([]);
    setSelectedColumns([]);
    setAvailableColumns([]);
    setAttributes([]);
    setFilters([{ column: '', type: 'contains', value: '' }]);
    setOrderBy('id');
    setOrderDir('ASC');
    setTotalPages(0);
    setTotalItems(0);
    setShowPopup(false);
    setPage(0);
    setInputPage(1);
    setErrorMessage(null);
    setColumnsLoaded(false);
    setLoading(true);

    const formData = new FormData();
    formData.append('itemTag', itemTag);

    if (feedFile) {
      formData.append('file', feedFile);
    } else if (feedUrl) {
      formData.append('feedUrl', feedUrl);
    }

    axios.post(`${API_BASE_URL}/api/load-xml`, formData, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      const newFeedId = response.data.feed_id;
      setFeedId(newFeedId);
      return newFeedId;
    })
    .then(newFeedId => {
      loadData(newFeedId);
    })
    .catch(error => {
      let errMessage = 'Failed to load XML feed.';
      let errSolution = '';
      
      if (error.response && error.response.data) {
        if (error.response.data.error) {
          errMessage = error.response.data.error;
        }
        
        // Get additional error details if available
        if (error.response.data.error_type) {
          errMessage += ` (${error.response.data.error_type})`;
        }
        
        if (error.response.data.solution) {
          errSolution = error.response.data.solution;
        }
      }
      
      console.error('Error loading XML!', errMessage);
      setErrorMessage(errSolution ? `${errMessage}\n\nSuggested solution: ${errSolution}` : errMessage);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (feedId !== null) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId, page, pageSize, appliedFilters, orderBy, orderDir]);

  useEffect(() => {
    setInputPage(page + 1);
  }, [page]);

  useEffect(() => {
    const savedSelectedColumns = localStorage.getItem('selectedColumns');
    if (savedSelectedColumns) {
      const parsedSelectedColumns = JSON.parse(savedSelectedColumns);
      setSelectedColumns(parsedSelectedColumns);

      const currentAvailableColumns = attributes.filter(attr => !parsedSelectedColumns.includes(attr));
      setAvailableColumns(sortAvailableColumns(currentAvailableColumns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  useEffect(() => {
    if (!loadingPage && loadingButton) {
      setLoadingButton(null);
    }
  }, [loadingPage, loadingButton]);

  useEffect(() => {
    if (!autoLoadRef.current) {
      if (feedUrlParam) {
        setFeedUrl(feedUrlParam);
        autoLoadRef.current = true;
      } else {
        const storedUrl = localStorage.getItem('pendingFeedUrl');
        if (storedUrl) {
          setFeedUrl(storedUrl);
          autoLoadRef.current = true;
        }
      }
    }
  }, [feedUrlParam]);

  useEffect(() => {
    if (autoLoadRef.current && feedUrl) {
      handleLoadXml();
      autoLoadRef.current = false;
    }
  }, [feedUrl]);

  const loadData = async (feedIdParam) => {
    setLoadingPage(true);
    try {
      const filterParams = appliedFilters.map((filter) => ({
        column: filter.column,
        type: filter.type,
        value: filter.value,
        logic: filter.logic,
      }));

      const response = await axios.get(`${API_BASE_URL}/api/get-feed-data`, {
        headers: {
          ...getAuthHeader(),
        },
        params: {
          feed_id: feedIdParam || feedId || urlFeedId,
          page: page + 1,
          page_size: pageSize,
          filters: JSON.stringify(filterParams),
          order_by: orderBy,
          order_dir: orderDir,
        },
      });

      const fetchedData = response.data.data;
      const allAttributes = fetchedData.length > 0
        ? Object.keys(fetchedData[0])
        : attributes;

      const normalizedData = fetchedData.map(item => {
        const completeItem = {};
        allAttributes.forEach(attr => {
          completeItem[attr] = item[attr] || '';
        });
        return completeItem;
      });

      setData(normalizedData);
      setTotalPages(response.data.total_pages);
      setTotalItems(response.data.total_items);

      if (fetchedData.length > 0) {
        setAttributes(allAttributes);
        setAvailableColumns(
          sortAvailableColumns(allAttributes).filter(col => !selectedColumns.includes(col))
        );
      }

      setColumnsLoaded(true);
      setIsSuccess(true);
    } catch (error) {
      console.error('Error loading data:', error);
      alert(`Error loading data: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoadingPage(false);
      setLoading(false);
    }
  };

  const handleFeedAudit = () => {
    navigate(`/feedaudit/${feedId}`);
  };

  const handleNextPage = () => {
    if (page < totalPages - 1 && !loadingPage) {
      setLoadingButton('next');
      setLoadingPage(true);
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0 && !loadingPage) {
      setLoadingButton('prev');
      setLoadingPage(true);
      setPage(prevPage => prevPage - 1);
    }
  };

  const handleInputChange = (e) => {
    const newPage = Number(e.target.value);
    if (newPage > 0 && newPage <= totalPages) {
      setInputPage(newPage);
    }
  };

  const handleInputBlur = () => {
    setPage(inputPage - 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPage(0);
    setInputPage(1);
  };

  const handleSelectColumn = () => {
    const newSelectedColumns = [...selectedColumns, ...selectedAvailableColumns];
    setSelectedColumns(newSelectedColumns);
    setAvailableColumns(availableColumns.filter(col => !selectedAvailableColumns.includes(col)));
    setSelectedAvailableColumns([]);

    localStorage.setItem('selectedColumns', JSON.stringify(newSelectedColumns));
  };

  const handleDeselectColumn = (column) => {
    const newSelectedColumns = selectedColumns.filter(col => col !== column);
    setSelectedColumns(newSelectedColumns);
    setAvailableColumns(sortAvailableColumns([...availableColumns, column]));

    localStorage.setItem('selectedColumns', JSON.stringify(newSelectedColumns));
  };

  const handleAvailableColumnClick = (column, index, event) => {
    if (event.shiftKey) {
      const lastIndex = availableColumns.findIndex(col => col === selectedAvailableColumns[selectedAvailableColumns.length - 1]);
      const range = [lastIndex, index].sort((a, b) => a - b);
      const newSelected = availableColumns.slice(range[0], range[1] + 1);
      setSelectedAvailableColumns([...new Set([...selectedAvailableColumns, ...newSelected])]);
    } else {
      setSelectedAvailableColumns(prev =>
        prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]
      );
    }
  };

  const handleShowPopup = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedItem(null);
  };

  const isImageUrl = (url) => {
    return (
      typeof url === 'string' &&
      (url.match(/\.(jpeg|jpg|gif|png|webp)(\?.*)?$/i) || url.match(/(jpeg|jpg|gif|png|webp)\?/i))
    );
  };

  const renderTableCell = (value) => {
    if (isImageUrl(value)) {
      return <img src={value} alt="Image" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
    }
    return value;
  };

  const moveColumn = (fromIndex, toIndex) => {
    const updatedColumns = [...selectedColumns];
    const [movedItem] = updatedColumns.splice(fromIndex, 1);
    updatedColumns.splice(toIndex, 0, movedItem);
    setSelectedColumns(updatedColumns);
  };

  const handleFeedUrlChange = (e) => {
    setFeedUrl(e.target.value);
    if (e.target.value) {
      setFeedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
    setIsSuccess(false);
  };

  const handleFileChange = (e) => {
    setFeedFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFeedUrl('');
    }
    setIsSuccess(false);
  };

  const handleFeedUrlKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLoadXml();
    }
  };

  const handleAddFilter = () => {
    setFilters([...filters, { column: '', type: 'contains', value: '', logic: 'AND' }]);
  };

  const handleRemoveFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const updateFilter = (index, key, value) => {
    const updatedFilters = filters.map((filter, i) =>
      i === index ? { ...filter, [key]: value } : filter
    );
    setFilters(updatedFilters);
  };

  const applyFilters = () => {
    setAppliedFilters([...filters]);
    setPage(0);
  };

  const handleOrderByChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleOrderDirChange = (e) => {
    setOrderDir(e.target.value);
  };

  const handleDownloadExcel = async () => {
    setLoadingDownload(true);

    const filterParams = filters.map((filter) => ({
      column: filter.column,
      type: filter.type,
      value: filter.value,
    }));

    const params = {
      feed_id: feedId,
      filters: JSON.stringify(filterParams),
      order_by: orderBy,
      order_dir: orderDir,
      columns: JSON.stringify(selectedColumns),
    };

    const queryParams = new URLSearchParams(params).toString();
    const url = `${API_BASE_URL}/api/export-feed-data?${queryParams}`;

    try {
      const response = await axios.get(url, {
        headers: {
          ...getAuthHeader(),
        },
        responseType: 'blob'
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'feed_data.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Download failed:", error);
      alert('Failed to download the file.');
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleExportToSheets = async () => {
    setLoadingSheets(true);
    setSheetsUrl(null);
    setShowSheetsSuccess(false);

    const filterParams = filters.map((filter) => ({
      column: filter.column,
      type: filter.type,
      value: filter.value,
      logic: filter.logic,
    }));

    const requestData = {
      feed_id: feedId,
      filters: filterParams,
      order_by: orderBy,
      order_dir: orderDir,
      columns: selectedColumns,
      sheet_title: `Feed Data Export - ${new Date().toLocaleString()}`
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/export-to-sheets`, requestData, {
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'application/json'
        }
      });

      setSheetsUrl(response.data.sheet_url);
      setShowSheetsSuccess(true);
      
      // Open the sheet in a new tab
      window.open(response.data.sheet_url, '_blank');
    } catch (error) {
      console.error("Google Sheets export failed:", error);
      alert(`Failed to export to Google Sheets: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoadingSheets(false);
    }
  };

  const handleCloseSheetsSucessPopup = () => {
    setShowSheetsSuccess(false);
  };

  const sortAvailableColumns = (columns) => {
    const order = [
      'item_group_id', 'id', 'gtin', 'mpn', 'title', 'short_title', 'description',
      'brand', 'product_type', 'google_product_category', 'material', 'pattern',
      'image_link', 'additional_image_link', 'additional_image_link_1', 'additional_image_link_2',
      'additional_image_link_3', 'additional_image_link_4', 'additional_image_link_5',
      'additional_image_link_6', 'additional_image_link_7', 'additional_image_link_8',
      'additional_image_link_9', 'additional_image_link_10',
    ];

    const orderedColumns = order.filter(col => columns.includes(col));
    const remainingColumns = columns.filter(col => !order.includes(col) && !col.startsWith('qfe_'));
    const qfeColumns = columns.filter(col => col.startsWith('qfe_'));

    return [...orderedColumns, ...remainingColumns, ...qfeColumns];
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.pageContainer}>
        
        {/* TOP BAR */}
        <div className={styles.topBar}>
          <button onClick={handleLogout} className={styles.logoutButton}>
            <FaPowerOff size={20} />
          </button>
          <div className={styles.headerTextContainer}>
            <span className={styles.feedViewerVersion}>Feed Viewer 2.0</span>
            <span className={styles.headerSubtitle}>Get ready to crunch some feeds!</span>
          </div>
          <img
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
            alt="Header"
            className={styles.headerImage}
          />
        </div>

        {/* MAIN CONTENT */}
        <div className={styles.content}>
          {/* FORM FOR URL / FILE */}
          <div className={styles.formContainer}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="https://feeds.datafeedwatch.com/1234/yourfeed.xml"
                value={feedUrl}
                onChange={handleFeedUrlChange}
                onKeyDown={handleFeedUrlKeyDown}
                className={`${styles.inputField} ${styles.feedUrlInput}`}
              />
            </div>

            <div className={styles.inputGroup}>
              <input
                type="file"
                accept=".csv, .tsv, .xml, .xls, .xlsx"
                onChange={handleFileChange}
                className={styles.fileInput}
                ref={fileInputRef}
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="itemTag">Tag:</label>
              <input
                type="text"
                id="itemTag"
                value={itemTag}
                onChange={(e) => {
                  setItemTag(e.target.value);
                  setIsSuccess(false);
                }}
                className={`${styles.inputField} ${styles.itemTagInput}`}
              />
            </div>

            <button 
              onClick={handleLoadXml} 
              className={styles.loadButton} 
              disabled={loading || isSuccess || (!feedFile && !feedUrl)}
            >
              {loading ? (
                <ClipLoader size={20} color={"#ffffff"} />
              ) : isSuccess ? (
                <FaCheck size={20} color={"#ffffff"} />
              ) : (
                <FaPlay size={20} color={"#ffffff"} />
              )}
            </button>

            <button 
              onClick={handleFeedAudit} 
              className={styles.auditButton}
              disabled={!feedId}
            >
              Audit
            </button>
          </div>

          {errorMessage && (
            <div className={styles.error}>
              {errorMessage.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}

          {attributes.length > 0 && (
            <div className={styles.columnSelectionContainer}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                  <p className={styles.columnSectionTitle}>The product feed contains these attributes</p>
                  <ul className={styles.columnList}>
                    {availableColumns.map((col, index) => (
                      <li
                        key={col}
                        className={`${styles.columnItem} ${selectedAvailableColumns.includes(col) ? styles.selectedColumn : ''}`}
                        onClick={(e) => handleAvailableColumnClick(col, index, e)}
                      >
                        {col}
                      </li>
                    ))}
                  </ul>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <button 
                    onClick={handleSelectColumn} 
                    disabled={selectedAvailableColumns.length === 0} 
                    className={styles.selectButton}
                  >
                    <FaArrowRight size={16} color={"#ffffff"} />
                  </button>
                </div>

                <div style={{ flex: 1 }}>
                  <p className={styles.columnSectionTitle}>Selected attributes to crunch</p>
                  <ul className={styles.columnList}>
                    {selectedColumns.map((col, index) => (
                      <DraggableColumn
                        key={col}
                        column={col}
                        index={index}
                        moveColumn={moveColumn}
                        onRemove={handleDeselectColumn}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {attributes.length > 0 && selectedColumns.length > 0 && (
            <div className={styles.filterContainer}>
              <h3>Filter Options</h3>

              {filters.map((filter, index) => (
                <div key={index} className={styles.filterRow}>
                  {index > 0 && (
                    <div className={styles.filterLogicSection}>
                      <select
                        value={filter.logic}
                        onChange={(e) => updateFilter(index, 'logic', e.target.value)}
                        className={styles.filterLogicDropdown}
                      >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                      </select>
                    </div>
                  )}

                  <select
                    value={filter.column}
                    onChange={(e) => updateFilter(index, 'column', e.target.value)}
                    className={styles.filterDropdown}
                  >
                    <option value="">Select a column</option>
                    {attributes.map(attr => (
                      <option key={attr} value={attr}>{attr}</option>
                    ))}
                  </select>

                  <select
                    value={filter.type}
                    onChange={(e) => updateFilter(index, 'type', e.target.value)}
                    className={styles.filterDropdown}
                  >
                    <option value="contains">contains</option>
                    <option value="does_not_contain">does not contain</option>
                    <option value="is_equal_to">is equal to</option>
                    <option value="is_not_equal_to">is not equal to</option>
                    <option value="starts_with">starts with</option>
                    <option value="ends_with">ends with</option>
                    <option value="is_blank">is blank</option>
                    <option value="is_not_blank">is not blank</option>
                    <option value="length_equals">length =</option>
                    <option value="length_greater_than">length greater than</option>
                    <option value="length_less_than">length less than</option>
                    <option value="is_greater_than">is greater than</option>
                    <option value="is_greater_or_equal_to">is greater or equal to</option>
                    <option value="is_less_than">is less than</option>
                    <option value="is_less_or_equal_to">is less or equal to</option>
                    <option value="matches_regexp">matches regexp</option>
                    <option value="doesnt_match_regexp">doesn't match regexp</option>
                  </select>

                  {![ 'is_blank', 'is_not_blank' ].includes(filter.type) && (
                    <input
                      type="text"
                      value={filter.value}
                      onChange={(e) => updateFilter(index, 'value', e.target.value)}
                      className={styles.filterInput}
                      placeholder="Filter value"
                    />
                  )}

                  <button
                    onClick={() => handleRemoveFilter(index)}
                    className={`${styles.deleteIcon} ${styles.filterDeleteButton}`}
                  >
                    &times;
                  </button>
                </div>
              ))}

              <div className={styles.orderBySection}>
                <span className={styles.sortByLabel}>Sort by:</span>
                <select
                  id="orderBy"
                  value={orderBy}
                  onChange={handleOrderByChange}
                >
                  <option value="" disabled hidden>Order by...</option>
                  {attributes.map(attr => (
                    <option key={attr} value={attr}>{attr}</option>
                  ))}
                </select>

                <select
                  id="orderDir"
                  value={orderDir}
                  onChange={handleOrderDirChange}
                >
                  <option value="ASC">ASC</option>
                  <option value="DESC">DESC</option>
                </select>

                <div className={styles.filterButtonsWrapper}>
                  <button onClick={handleAddFilter} className={styles.addFilterButton}>
                    <FaPlus />
                  </button>
                  <button onClick={applyFilters} className={styles.applyFilterButton}>
                    <FaCheck /> Apply Filter
                  </button>
                </div>
              </div>
            </div>
          )}

          {!loading && data.length > 0 && selectedColumns.length > 0 && (
            <div className={styles.tableContainer}>
              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th className={styles.actionColumn}></th>
                      {selectedColumns.map((attr) => (
                        <th key={attr}>{attr}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={item.id || index}>
                        <td className={styles.actionColumn}>
                          <button onClick={() => handleShowPopup(item)} className={styles.popupButton}>
                            <FaEye size={16} />
                          </button>
                        </td>
                        {selectedColumns.map((attr) => (
                          <td key={attr}>{renderTableCell(item[attr])}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p style={{ 
                textAlign: 'center', 
                fontSize: '12px', 
                color: '#666', 
                margin: '20px 0' 
              }}>
                Disclaimer: s360 Digital A/S is not responsible for the content displayed in this tool.
              </p>
              <div className={styles.paginationContainer}>
                <button
                  onClick={handleDownloadExcel}
                  disabled={loadingDownload}
                  className={styles.downloadButton}
                >
                  {loadingDownload ? (
                    <ClipLoader size={20} color={"#848EE0"} />
                  ) : (
                    <FaDownload />
                  )}
                </button>

                <button
                  onClick={handleExportToSheets}
                  disabled={loadingSheets}
                  className={styles.sheetsButton}
                  title="Export to Google Sheets"
                >
                  {loadingSheets ? (
                    <ClipLoader size={16} color={"#4CAF50"} />
                  ) : (
                    <FaTable size={20} />
                  )}
                </button>

                {showSheetsSuccess && (
                  <>
                    <div className={styles.sheetsPopupOverlay} onClick={handleCloseSheetsSucessPopup}></div>
                    <div className={styles.sheetsSuccessPopup}>
                      <button onClick={handleCloseSheetsSucessPopup} className={styles.sheetsCloseButton}>
                        ×
                      </button>
                      <div className={styles.popupIcon}>✓</div>
                      <h3>Export Successful!</h3>
                      <p>Your data has been exported to Google Sheets.</p>
                      {sheetsUrl && (
                        <a href={sheetsUrl} target="_blank" rel="noopener noreferrer">
                          Open in Sheets
                        </a>
                      )}
                    </div>
                  </>
                )}

                <select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  disabled={loadingPage}
                >
                  <option value="10">10</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="2000">2000</option>
                </select>

                <p>
                  Page 
                  <input
                    type="number"
                    value={inputPage}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{ width: '50px', margin: '0 5px', textAlign: 'center' }}
                    min="1"
                    max={totalPages}
                    disabled={loadingPage}
                  />
                  of {totalPages} ({totalItems} items)
                </p>

                <button
                  onClick={handlePrevPage}
                  disabled={page === 0 || loadingButton !== null}
                >
                  {loadingButton === 'prev' ? (
                    <ClipLoader size={20} color={"#ffffff"} />
                  ) : (
                    <FaArrowLeft />
                  )}
                </button>

                <button
                  onClick={handleNextPage}
                  disabled={page >= totalPages - 1 || loadingButton !== null}
                >
                  {loadingButton === 'next' ? (
                    <ClipLoader size={20} color={"#ffffff"} />
                  ) : (
                    <FaArrowRight />
                  )}
                </button>
              </div>

              {showPopup && selectedItem && (
                <div className={styles.popupOverlay} onClick={handleClosePopup}>
                  <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                    <button onClick={handleClosePopup} className={styles.closeButton}>
                      &times;
                    </button>
                    <h2>Item Details</h2>

                    {['link', 'item_group_id', 'id', 'gtin', 'mpn', 'title', 'short_title', 'brand', 'description', 'image_link']
                      .filter(attr => selectedItem[attr])
                      .map(attr => (
                        <div key={attr} className={styles.itemDetailRow}>
                          <strong>{attr}</strong>
                          {isImageUrl(selectedItem[attr]) ? (
                            <img src={selectedItem[attr]} alt={attr} className={styles.popupImage} />
                          ) : (
                            <span>{selectedItem[attr]}</span>
                          )}
                          {attr === 'link' && (
                            <div className={styles.productPageContainer}>
                              <a href={selectedItem[attr]} target="_blank" rel="noopener noreferrer">
                                <button className={styles.productPageButton}>
                                  <FaLink size={16} className={styles.productPageIcon} />
                                </button>
                              </a>
                            </div>
                          )}
                        </div>
                      ))
                    }

                    <div className={styles.itemDetailRow}>
                      <strong className={styles.sectionHeader}>additional_image_link</strong>
                      <div className={styles.imageSection}>
                        {Object.entries(selectedItem)
                          .filter(([key]) => /^additional_image_link(_\d+)?$/.test(key))
                          .sort(([keyA], [keyB]) => {
                            const numA = parseInt(keyA.split('_').pop(), 10) || 1;
                            const numB = parseInt(keyB.split('_').pop(), 10) || 1;
                            return numA - numB;
                          })
                          .map(([key, value], idx) => (
                            isImageUrl(value) && (
                              <div key={key} className={styles.imageWrapper}>
                                <img src={value} alt={`Additional ${idx + 1}`} className={styles.popupImage} />
                                <span className={styles.imageLabel}>{idx + 1}</span>
                              </div>
                            )
                          ))
                        }
                      </div>
                    </div>

                    {Object.entries(selectedItem)
                      .filter(([key]) =>
                        ![
                          'link','item_group_id','id','gtin','mpn','title','short_title',
                          'brand','description','image_link'
                        ].includes(key) &&
                        !key.startsWith('qfe_') &&
                        !key.startsWith('additional_image_link')
                      )
                      .map(([key, value]) => (
                        <div key={key} className={styles.itemDetailRow}>
                          <strong>{key}</strong>
                          {isImageUrl(value) ? (
                            <img src={value} alt={key} className={styles.popupImage} />
                          ) : (
                            <span>{value}</span>
                          )}
                        </div>
                      ))
                    }

                    {Object.entries(selectedItem)
                      .filter(([key]) => key.startsWith('qfe_'))
                      .map(([key, value]) => (
                        <div key={key} className={styles.itemDetailRow}>
                          <strong>{key}</strong>
                          {isImageUrl(value) ? (
                            <img src={value} alt={key} className={styles.popupImage} />
                          ) : (
                            <span>{value}</span>
                          )}
                        </div>
                      ))
                    }
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* --- ADD YOUR DISCLAIMER HERE --- */}


      </div>
    </DndProvider>
  );
}

export default FeedViewer;
