import React, { useState } from 'react';
import styles from '../css/Login.module.css';

function Login() {
  const [consentChecked, setConsentChecked] = useState(false);

  const handleConsentChange = (e) => {
    setConsentChecked(e.target.checked);
  };

  const handleGoogleSignIn = () => {
    if (!consentChecked) return;
    // Redirect to your Google auth URL
    window.location.href = process.env.REACT_APP_GOOGLE_AUTH_URL;
  };

  return (
    <div className={styles.loginContainer}>
      {/* Hero Section */}
      <div className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>Feed Viewer & Audit Tool</h1>
          <p className={styles.heroSubtitle}>
            Powerful analytics and visualization for your product feeds
          </p>
          
          <div className={styles.heroButtons}>
            <button
              onClick={handleGoogleSignIn}
              className={styles.googleSignInButton}
              disabled={!consentChecked}
            >
              <span className={styles.googleButtonIcon}>
                <img
                  src="https://storage.googleapis.com/quantum-feed-engine-application/google_logo-google_icongoogle-512.webp"
                  alt="Google"
                />
              </span>
              <span>Sign in with Google</span>
            </button>
          </div>
          
          <label className={styles.consentLabel}>
            <input
              type="checkbox"
              className={styles.consentCheckbox}
              checked={consentChecked}
              onChange={handleConsentChange}
            />
            <span className={styles.checkboxText}>Yes, give my consent to login with Google SSO*</span>
          </label>
          
          {/* Consent Information */}
          <div className={styles.consentBox}>
            <p>
              When you access our Feed Viewer & Audit tool, through the above
              button, you will login via Google's SSO and your name, e-mail and other
              data will automatically be stored in our CRM system. For more information
              on how we store and process your personal data please review our{' '}
              <a
                href="https://s360digital.com/da/privatlivspolitik/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>.
            </p>
            <p>
              By clicking Sign in with Google, you consent to s360 Digital A/S storing and
              processing the personal information we receive from your Google SSO login
              as set out above.
            </p>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className={styles.featuresSection}>
        <h2 className={styles.sectionTitle}>Simplify Your Feed Management</h2>
        <div className={styles.featuresGrid}>
          <div className={styles.featureCard}>
            <div className={styles.featureIcon}>🔍</div>
            <h3>Feed Viewer</h3>
            <p>Easily explore XML feeds with powerful filtering and sorting capabilities</p>
          </div>
          <div className={styles.featureCard}>
            <div className={styles.featureIcon}>📊</div>
            <h3>Feed Audit</h3>
            <p>Gain insights on title quality, description completeness, and attribute usage</p>
          </div>
          <div className={styles.featureCard}>
            <div className={styles.featureIcon}>📱</div>
            <h3>Export Options</h3>
            <p>Export your findings to Excel or Google Sheets for further analysis</p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className={styles.loginFooter}>
        <p>
          Developed by <strong>s360 Digital A/S</strong> 
        </p>
        <p className={styles.privacyPolicyLink}>
          <a 
            href="https://s360digital.com/da/privatlivspolitik/" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Privacy Policy"
            id="privacy-policy-link"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default Login;
