// src/App.js
import React, { useEffect } from 'react';
import { 
  HashRouter as Router, 
  Route, 
  Routes, 
  Navigate
} from 'react-router-dom';

import FeedViewer from './pages/FeedViewer';
import FeedAudit from './pages/FeedAudit';
import Login from './pages/Login';
import AuthCallback from './pages/AuthCallback';

// Set this to true to temporarily make protected routes public
const BYPASS_AUTH = process.env.REACT_APP_DEVELOPMENT_MODE === "true";

function ProcessFeedUrlParam() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const feedUrl = params.get('url');
    if (feedUrl) {
      localStorage.setItem('pendingFeedUrl', feedUrl);
    } else {
      localStorage.removeItem('pendingFeedUrl');
    }
  }, []);

  return null;
}

function PrivateRoute({ children }) {
  // Check the bypass flag first, if true, render the children regardless of auth status
  if (BYPASS_AUTH) { // DELETE THIS LATER
    return children; // DELETE THIS LATER
  } // DELETE THIS LATER
  
  const isAuthenticated = !!localStorage.getItem('jwt');
  return isAuthenticated ? children : <Navigate to="/" />;
}

function App() {
  return (
    <Router>
      <ProcessFeedUrlParam />
      <Routes>
        {/* Make Login the root/homepage */}
        <Route path="/" element={<Login />} />

        {/* Redirect /login to root */}
        <Route path="/login" element={<Navigate to="/" />} />

        {/* Public route: Google OAuth callback */}
        <Route path="/auth/callback" element={<AuthCallback />} />

        {/* Protected route: FeedViewer */}
        <Route 
          path="/feedviewer"
          element={
            <PrivateRoute>
              <FeedViewer />
            </PrivateRoute>
          }
        />
        <Route 
          path="/feedviewer/:feedId"
          element={
            <PrivateRoute>
              <FeedViewer />
            </PrivateRoute>
          }
        />

        {/* Protected route: FeedAudit */}
        <Route 
          path="/feedaudit/:feedId"
          element={
            <PrivateRoute>
              <FeedAudit />
            </PrivateRoute>
          }
        />

        {/* Catch-all: Redirect any unknown URL to root (login) */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
